import React,{
	useState
} from 'react';
import ReactDOM from 'react-dom';

const PayPalButton = 
	window.paypal.Buttons.driver("react",{
		React,
		ReactDOM
	});

export default function Landingpage(){

	const [startEnrollment, setStartEnrollment] = 
											useState(false);

	const createOrder =  (data,actions)=>{

		return actions.order.create({
			purchase_units : [
				{
					amount : {
						value : "25.00"
					}
				}
			]
		});
	}

	const onApprove = (data, actions)=>{

		return actions.order.capture()
					.then((details)=>{

						console.log(details);
					});
	}

	const initEnrollment =  ()=>{
		setStartEnrollment(true);
	}

	if(startEnrollment){
		return (<div>
			<h3>Pay with Paypal</h3>
			<PayPalButton
				createOrder={(data,actions)=>createOrder(data,actions)}
				onApprove={(data, actions)=>onApprove(data,actions)}/>
		</div>);
	}


	return (<div>
		<h3>Landingpage</h3>

		<button 
			type="button"
			onClick={initEnrollment}>
			Buy Now
		</button>
	</div>);
}