import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';
import Enroll from './components/enroll';
import Landingpage from './components/landingpage';
import Home from './components/home';

export default function App(){
  return(<Router>
    <Switch>
      <Route path="/enroll">
        <Enroll/>
      </Route>
      <Route path="/landingpage">
        <Landingpage/>
      </Route>
      <Route path="/">
        <Home/>
      </Route>
    </Switch>
  </Router>);
}